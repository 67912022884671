import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import { User, Mail, Phone, MapPin, Home, Flag, Key, Loader, CheckCircle, AlertCircle } from 'lucide-react'
import axiosApi from '../api/axiosApi'
import Select from 'react-select'
import { useFormik } from 'formik'
import * as yup from 'yup'

// List of Indian states
const indianStates = [
    { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
    { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
    { value: 'Assam', label: 'Assam' },
    { value: 'Bihar', label: 'Bihar' },
    { value: 'Chhattisgarh', label: 'Chhattisgarh' },
    { value: 'Delhi', label: 'Delhi' },
    { value: 'Goa', label: 'Goa' },
    { value: 'Gujarat', label: 'Gujarat' },
    { value: 'Haryana', label: 'Haryana' },
    { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
    { value: 'Jammu and Kashmir', label: 'Jammu and Kashmir' },
    { value: 'Jharkhand', label: 'Jharkhand' },
    { value: 'Karnataka', label: 'Karnataka' },
    { value: 'Kerala', label: 'Kerala' },
    { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
    { value: 'Maharashtra', label: 'Maharashtra' },
    { value: 'Manipur', label: 'Manipur' },
    { value: 'Meghalaya', label: 'Meghalaya' },
    { value: 'Mizoram', label: 'Mizoram' },
    { value: 'Nagaland', label: 'Nagaland' },
    { value: 'Odisha', label: 'Odisha' },
    { value: 'Punjab', label: 'Punjab' },
    { value: 'Rajasthan', label: 'Rajasthan' },
    { value: 'Sikkim', label: 'Sikkim' },
    { value: 'Tamil Nadu', label: 'Tamil Nadu' },
    { value: 'Telangana', label: 'Telangana' },
    { value: 'Tripura', label: 'Tripura' },
    { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
    { value: 'Uttarakhand', label: 'Uttarakhand' },
    { value: 'West Bengal', label: 'West Bengal' },
]

// Validation schema
const validationSchema = yup.object({
    name: yup.string().required('Full Name is required'),
    email: yup.string().email('Invalid email address').required('Email is required'),
    mobile: yup.string().matches(/^[0-9]{10}$/, 'Mobile number must be 10 digits').required('Mobile number is required'),
    address: yup.string().required('Address is required'),
    state: yup.string().required('State is required'),
    city: yup.string().required('City is required'),
    pincode: yup.string().matches(/^[0-9]{6}$/, 'Pincode must be 6 digits').required('Pincode is required'),
    username: yup.string().required('Username is required'),
    password: yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
})

export default function DealerRegistration() {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            mobile: '',
            address: '',
            state: '',
            city: '',
            pincode: '',
            username: '',
            password: '',
        },
        validationSchema,
        onSubmit: async (values) => {
            setIsLoading(true)
            setErrorMessage('') // Clear any previous error messages
            try {
                const registrationData = {
                    ...values,
                    role: 'dealer',
                    parent_id: 1,
                }
                const response = await axiosApi.post('/register', registrationData)
                console.log('Dealer registration successful:', response.data)
                setShowSuccessModal(true)
                setTimeout(() => {
                    setShowSuccessModal(false)
                    navigate('/dealers')
                }, 2000)
            } catch (error) {
                console.error('Error registering dealer:', error)
                if (error.response && error.response.status === 409) {
                    setErrorMessage('User with this Email, Username, or Mobile already exists')
                } else {
                    setErrorMessage('An error occurred while registering. Please try again.')
                }
            } finally {
                setIsLoading(false)
            }
        },
    })

    const selectStyles = {
        control: (base) => ({
            ...base,
            minHeight: '42px',
            paddingLeft: '2.5rem',
            borderRadius: '0.5rem',
            borderColor: '#e5e7eb',
            backgroundColor: 'white',
            '&:hover': { borderColor: '#84cc16' },
        }),
        input: (base) => ({
            ...base,
            margin: 0,
            padding: 0,
        }),
        menu: (base) => ({
            ...base,
            backgroundColor: 'white',
            zIndex: 1000,
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused ? '#f3f4f6' : 'white',
            '&:active': {
                backgroundColor: '#e5e7eb',
            },
            '&[aria-selected="true"]': {
                backgroundColor: '#34d399', // Keep this green color for selected options
            },
        }),
    };

    const inputFields = [
        { name: 'name', icon: User, placeholder: 'Full Name' },
        { name: 'email', icon: Mail, placeholder: 'Email Address' },
        { name: 'mobile', icon: Phone, placeholder: 'Mobile Number' },
        { name: 'address', icon: MapPin, placeholder: 'Address' },
        { name: 'state', icon: Flag, placeholder: 'State', type: 'select' },
        { name: 'city', icon: Home, placeholder: 'City' },
        { name: 'pincode', icon: MapPin, placeholder: 'Pincode', type: 'number' },
        { name: 'username', icon: User, placeholder: 'Username' },
        { name: 'password', icon: Key, placeholder: 'Password', type: 'password' },
    ]

    return (
        <motion.div
            className="max-w-md mx-auto bg-white p-6 rounded-xl shadow-2xl"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <h2 className="text-2xl font-bold mb-6 text-center text-lime-600">Dealer Registration</h2>
            <form onSubmit={formik.handleSubmit} className="space-y-4">
                {inputFields.map((field) => (
                    <div key={field.name} className="relative">
                        <field.icon className="absolute top-3 left-3 text-gray-400 z-10" size={20} />
                        {field.type === 'select' ? (
                            <Select
                                options={indianStates}
                                onChange={(selectedOption) => formik.setFieldValue('state', selectedOption.value)}
                                placeholder="Select State"
                                className="w-full"
                                styles={selectStyles}
                                onBlur={() => formik.setFieldTouched('state', true)}
                            />
                        ) : (
                            <input
                                type={field.type || 'text'}
                                name={field.name}
                                placeholder={field.placeholder}
                                required
                                className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                                value={formik.values[field.name]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                maxLength={field.name === 'pincode' ? 6 : undefined}
                            />
                        )}
                        {formik.touched[field.name] && formik.errors[field.name] ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors[field.name]}</div>
                        ) : null}
                    </div>
                ))}

                {errorMessage && (
                    <motion.div
                        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                        role="alert"
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                    >
                        <AlertCircle className="inline-block mr-2" size={20} />
                        <span className="block sm:inline">{errorMessage}</span>
                    </motion.div>
                )}

                <motion.button
                    type="submit"
                    className="w-full bg-lime-600 text-white py-2 px-4 rounded-lg hover:bg-lime-700 transition duration-300 flex items-center justify-center"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <Loader className="animate-spin mr-2" size={20} />
                    ) : null}
                    {isLoading ? 'Registering...' : 'Register Dealer'}
                </motion.button>
            </form>

            <AnimatePresence>
                {showSuccessModal && (
                    <motion.div
                        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <motion.div
                            className="bg-white p-6 rounded-lg shadow-xl text-center z-50"
                            initial={{ scale: 0.8, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.8, opacity: 0 }}
                        >
                            <CheckCircle className="text-lime-600 w-16 h-16 mx-auto mb-4" />
                            <h3 className="text-2xl font-bold text-gray-800 mb-2">Registration Successful!</h3>
                            <p className="text-gray-600">The dealer has been registered successfully.</p>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    )
}