import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import { Plus, Trash2, User, Search, Filter, ChevronDown, ChevronUp, ExternalLink, Loader, BlocksIcon, Lock, Edit } from 'lucide-react'
import ConfirmationDialog from './ConfirmationDialog'
import axiosApi from '../api/axiosApi'
import { useAuth } from '../contexts/AuthContext'

export default function AssociatesManagement() {
    const navigate = useNavigate()
    const [associates, setAssociates] = useState([])
    const [deleteConfirmation, setDeleteConfirmation] = useState({ isOpen: false, associateId: null, parent_id: null })
    const [showFilters, setShowFilters] = useState(false)
    const [filters, setFilters] = useState({
        status: '',
        location: '',
        dealer: '',
        search: '',
    })
    const [isDeleting, setIsDeleting] = useState(false)
    const { user } = useAuth()
    const [isAdmin, setIsAdmin] = useState(false)
    const [dealers, setDealers] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        fetchAssociates()
        setIsAdmin(user.role === 'admin')
        if (user.role === 'admin') {
            fetchDealers()
        }
    }, [user.role])

    const fetchAssociates = async () => {
        setIsLoading(true)
        try {
            const response = await axiosApi.post('/getAllAssociates')
            const associatesData = response.data.data;
            setAssociates(associatesData)
        } catch (error) {
            console.error('Error fetching associates:', error)
        } finally {
            setIsLoading(false)
        }
    }

    const fetchDealers = async () => {
        try {
            const response = await axiosApi.get('/getAllUserByParent')
            const dealersData = response.data.data.filter(user => user.role === 'dealer')
            setDealers(dealersData)
        } catch (error) {
            console.error('Error fetching dealers:', error)
        }
    }

    const getDealerName = (dealerId) => {
        const dealer = dealers.find(d => d.id === dealerId)
        return dealer ? dealer.name : 'Admin'
    }

    const handleRemoveAssociate = (id, parent_id) => {
        setDeleteConfirmation({ isOpen: true, associateId: id, parent_id: parent_id })
    }

    const confirmDelete = async () => {
        if (deleteConfirmation.associateId) {
            setIsDeleting(true)
            try {
                await axiosApi.post(`/deleteUser?userToDelete=${deleteConfirmation.associateId}&parent_id=${deleteConfirmation.parent_id}`)
                setAssociates(associates.filter((associate) => associate.id !== deleteConfirmation.associateId))
            } catch (error) {
                console.error('Error deleting associate:', error)
                // Handle error (e.g., show error message to user)
            } finally {
                setIsDeleting(false)
                setDeleteConfirmation({ isOpen: false, associateId: null, parent_id: null })
            }
        }
    }

    const handleFilterChange = (e) => {
        const { name, value } = e.target
        setFilters(prevFilters => ({ ...prevFilters, [name]: value }))
    }

    const filteredAssociates = associates.filter(associate => {
        return (
            (filters.status === '' || associate.status === filters.status) &&
            (filters.location === '' || associate.city.toLowerCase().includes(filters.location.toLowerCase())) &&
            (filters.dealer === '' || (associate.dealer && associate.dealer.toLowerCase().includes(filters.dealer.toLowerCase()))) &&
            (filters.search === '' ||
                associate.name.toLowerCase().includes(filters.search.toLowerCase()) ||
                associate.email.toLowerCase().includes(filters.search.toLowerCase()))
        )
    })

    const handleDealerClick = (dealerId) => {
        const dealer = dealers.find(d => d.id === dealerId)
        if (dealer) {
            navigate(`/dealers/${dealerId}`)
        }

    }

    const handleEditAssociate = (e, associate) => {
        e.stopPropagation(); // Prevent the click event from bubbling up
        navigate(`/associates/edit/${associate.id}`, { state: { associateToEdit: associate } });
    }

    const handleAssociateClick = (associateId) => {
        navigate(`/associates/${associateId}/reports`);
    }

    return (
        <motion.div
            className="bg-white shadow-lg rounded-lg overflow-hidden"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="px-4 sm:px-6 py-4 bg-lime-600 text-white flex flex-col sm:flex-row justify-between items-center">
                <h3 className="text-xl font-semibold mb-2 sm:mb-0">Associates</h3>

            </div>
            <div className="p-4 sm:p-6">
                <div className="mb-6">
                    <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
                        <div className="relative flex-grow w-full sm:w-auto sm:mr-4 mb-2 sm:mb-0">
                            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                            <input
                                type="text"
                                name="search"
                                placeholder="Search associates..."
                                value={filters.search}
                                onChange={handleFilterChange}
                                className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                            />
                        </div>
                        <motion.button
                            onClick={() => setShowFilters(!showFilters)}
                            className="flex items-center text-lime-600 hover:text-lime-800 w-full sm:w-auto justify-center sm:justify-start"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <Filter size={20} className="mr-2" />
                            Filters
                            {showFilters ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                        </motion.button>
                    </div>
                    <AnimatePresence>
                        {showFilters && (
                            <motion.div
                                className="grid grid-cols-1 gap-4"
                                initial={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: 'auto' }}
                                exit={{ opacity: 0, height: 0 }}
                            >
                                <select
                                    name="status"
                                    value={filters.status}
                                    onChange={handleFilterChange}
                                    className="w-full px-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                                >
                                    <option value="">All Statuses</option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                                <input
                                    type="text"
                                    name="location"
                                    placeholder="Filter by location"
                                    value={filters.location}
                                    onChange={handleFilterChange}
                                    className="w-full px-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                                />
                                <input
                                    type="text"
                                    name="dealer"
                                    placeholder="Filter by dealer"
                                    value={filters.dealer}
                                    onChange={handleFilterChange}
                                    className="w-full px-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                                />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
                {isLoading ? (
                    <div className="flex justify-center items-center h-64">
                        <Loader className="animate-spin text-lime-600" size={48} />
                    </div>
                ) : (
                    <AnimatePresence>
                        {filteredAssociates.map((associate) => (
                            <motion.div
                                key={associate.id}
                                className="bg-gray-50 rounded-lg p-4 mb-4 flex flex-col sm:flex-row justify-between items-start sm:items-center cursor-pointer"
                                onClick={() => handleAssociateClick(associate.id)}
                                initial={{ opacity: 0, y: -20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -20 }}
                                transition={{ duration: 0.3 }}
                            >
                                <div className="flex items-start sm:items-center mb-2 sm:mb-0">
                                    {associate.image ? (
                                        <img
                                            src={associate.image}
                                            alt={associate.name}
                                            className="w-10 h-10 rounded-full object-cover mr-4 border-2 border-lime-600"
                                        />
                                    ) : (
                                        <div className="w-10 h-10 rounded-full bg-lime-100 flex items-center justify-center mr-4 border-2 border-lime-600">
                                            <span className="text-lg text-lime-600 font-semibold">{associate.name.charAt(0)}</span>
                                        </div>
                                    )}
                                    <div>
                                        <h4 className="text-lg font-semibold text-gray-800">{associate.name}</h4>
                                        <p className="text-sm text-gray-600">{associate.email}</p>
                                        <div className="flex flex-wrap gap-2 mt-1">
                                            <span className="inline-block px-2 py-1 text-xs font-semibold rounded-full bg-green-200 text-green-800">
                                                Active
                                            </span>
                                            <span className="inline-block px-2 py-1 text-xs font-semibold rounded-full bg-blue-200 text-blue-800">
                                                {associate.city}
                                            </span>
                                            {associate.parent_id && (
                                                <motion.span
                                                    className={`inline-flex items-center px-2 py-1 text-xs font-semibold rounded-full bg-purple-200 text-purple-800 ${getDealerName(associate.parent_id) !== 'Admin' ? 'cursor-pointer' : ''}`}
                                                    onClick={() => handleDealerClick(associate.parent_id)}
                                                    whileHover={getDealerName(associate.parent_id) !== 'Admin' ? { scale: 1.05 } : {}}
                                                    whileTap={getDealerName(associate.parent_id) !== 'Admin' ? { scale: 0.95 } : {}}
                                                >
                                                    Dealer: {getDealerName(associate.parent_id)}
                                                    {getDealerName(associate.parent_id) !== 'Admin' && <ExternalLink size={12} className="ml-1" />}
                                                </motion.span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center mt-2 sm:mt-0">
                                    {isAdmin && (
                                        <motion.button
                                            onClick={(e) => handleEditAssociate(e, associate)}
                                            className="text-blue-600 hover:text-blue-800 mr-4"
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                        >
                                            <Edit size={20} />
                                        </motion.button>
                                    )}
                                    <motion.button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleRemoveAssociate(associate.id, associate.parent_id);
                                        }}
                                        className="text-red-600 hover:text-red-800"
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        disabled={isDeleting}
                                    >
                                        <Lock size={20} />
                                    </motion.button>
                                </div>
                            </motion.div>
                        ))}
                    </AnimatePresence>
                )}
            </div>
            <ConfirmationDialog
                isOpen={deleteConfirmation.isOpen}
                onClose={() => setDeleteConfirmation({ isOpen: false, associateId: null })}
                onConfirm={() => confirmDelete()}
                title="Block Associate"
                message="Are you sure you want to block this associate? This action cannot be undone."
                isLoading={isDeleting}
                confirmText={isDeleting ? 'Blocking...' : 'Block'}
                confirmIcon={isDeleting ? Loader : Lock}
            />
        </motion.div>
    )
}