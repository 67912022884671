import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Plus, Users, FileText, Briefcase } from 'lucide-react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, BarElement } from 'chart.js'
import { Pie, Line, Bar } from 'react-chartjs-2'
import axiosApi from '../api/axiosApi'

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, BarElement)

export default function DealersManagement() {
    const navigate = useNavigate()
    const [dealers, setDealers] = useState([])
    const [totalAssociates, setTotalAssociates] = useState(0)
    const [totalReports, setTotalReports] = useState(0)
    const [analytics, setAnalytics] = useState({ dealer: 0, associate: 0, reports: 0 })

    useEffect(() => {
        fetchDealers()
        fetchAnalytics()
    }, [])

    const fetchAnalytics = async () => {
        try {
            const response = await axiosApi.get('/getAnalytics')
            setAnalytics(response.data.data)
        } catch (error) {
            console.error('Error fetching analytics:', error)
        }
    }

    const fetchDealers = async () => {
        try {
            const response = await axiosApi.get('/getAllUserByParent')
            const dealersData = response.data.data.filter(user => user.role === 'dealer')
            const associatesData = response.data.data.filter(user => user.role === 'associate')

            setTotalAssociates(associatesData.length)

            const dealersWithAssociates = await Promise.all(dealersData.map(async (dealer) => {
                const associatesResponse = await axiosApi.get(`/getAssociateByDealer?dealerId=${dealer.id}`)
                return {
                    ...dealer,
                    associateCount: associatesResponse.data.data.length
                }
            }))

            setDealers(dealersWithAssociates)

            const totalAssociatesCount = dealersWithAssociates.reduce((sum, dealer) => sum + dealer.associateCount, 0)
            setTotalAssociates(totalAssociatesCount)

        } catch (error) {
            console.error('Error fetching dealers:', error)
        }
    }

    const pieChartData = {
        labels: dealers.map(dealer => dealer.name),
        datasets: [
            {
                data: dealers.map(dealer => dealer.associateCount),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.8)',
                    'rgba(54, 162, 235, 0.8)',
                    'rgba(255, 206, 86, 0.8)',
                    'rgba(75, 192, 192, 0.8)',
                ],
            },
        ],
    }

    const lineChartData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: dealers.map((dealer, index) => ({
            label: dealer.name,
            data: Array(6).fill().map(() => Math.floor(Math.random() * 100)),
            borderColor: `hsl(${index * 90}, 70%, 50%)`,
            tension: 0.1,
        })),
    }

    const barChartData = {
        labels: dealers.map(dealer => dealer.name),
        datasets: [
            {
                label: 'Total Reports',
                data: dealers.map(dealer => dealer.totalReports),
                backgroundColor: 'rgba(75, 192, 192, 0.8)',
            },
        ],
    }

    return (
        <motion.div
            className="bg-white shadow-lg rounded-lg overflow-hidden"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="px-6 py-4 bg-lime-600 text-white flex justify-between items-center">

                <div className="flex items-center">
                    <motion.button
                        onClick={() => navigate('/dealers/new')}
                        className="bg-white text-lime-600 font-bold py-2 px-4 rounded-full flex items-center"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        <Plus size={20} className="mr-2" />
                        Add Dealer
                    </motion.button>
                    <motion.button
                        onClick={() => navigate('/associates/new')}
                        className="bg-white text-lime-600 font-bold py-2 px-4 rounded-full flex items-center ml-4"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        <Plus size={20} className="mr-2" />
                        Add Associate
                    </motion.button>
                </div>
            </div>
            <div className="p-6">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
                    <motion.div
                        className="bg-purple-100 p-4 rounded-lg flex items-center justify-between cursor-pointer"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => navigate('/dealer-list')}
                    >
                        <div>
                            <p className="text-purple-800 font-semibold">Total Dealers</p>
                            <h4 className="text-2xl font-bold text-purple-900">{analytics.dealer}</h4> {/* Updated to use analytics data */}
                        </div>
                        <Briefcase size={40} className="text-purple-500" />
                    </motion.div>
                    <motion.div
                        className="bg-purple-100 p-4 rounded-lg flex items-center justify-between cursor-pointer"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => navigate('/associates')}
                    >

                        <div>
                            <p className="text-blue-800 font-semibold">Total Associates</p>
                            <h4 className="text-2xl font-bold text-blue-900">{analytics.associate}</h4> {/* Updated to use analytics data */}
                        </div>
                        <Users size={40} className="text-blue-500" />

                    </motion.div>
                    <motion.div
                        className="bg-green-100 p-4 rounded-lg flex items-center justify-between cursor-pointer"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => navigate('/reports')}
                    >

                        <div>
                            <p className="text-green-800 font-semibold">Total Reports</p>
                            <h4 className="text-2xl font-bold text-green-900">{analytics.reports}</h4>
                        </div>
                        <FileText size={40} className="text-green-500" />

                    </motion.div>
                </div >

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
                    <div className="bg-white p-4 rounded-lg shadow">
                        <h4 className="text-lg font-semibold mb-2">Associates Distribution</h4>
                        <Pie data={pieChartData} />
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow">
                        <h4 className="text-lg font-semibold mb-2">Monthly Reports Trend</h4>
                        <Line data={lineChartData} />
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow">
                        <h4 className="text-lg font-semibold mb-2">Total Reports by Dealer</h4>
                        <Bar data={barChartData} />
                    </div>
                </div>
            </div >
        </motion.div >
    )
}