import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import { User, Mail, Phone, MapPin, Home, Flag, Key, Loader, CheckCircle, AlertCircle, Image as ImageIcon } from 'lucide-react'
import axiosApi from '../api/axiosApi'
import { useAuth } from '../contexts/AuthContext'
import Select from 'react-select'
import { useFormik } from 'formik'
import * as yup from 'yup'
import imageCompression from 'browser-image-compression';

const indianStates = [

    { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
    { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
    { value: 'Assam', label: 'Assam' },
    { value: 'Bihar', label: 'Bihar' },
    { value: 'Chhattisgarh', label: 'Chhattisgarh' },
    { value: 'Delhi', label: 'Delhi' },
    { value: 'Goa', label: 'Goa' },
    { value: 'Gujarat', label: 'Gujarat' },
    { value: 'Haryana', label: 'Haryana' },
    { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
    { value: 'Jammu and Kashmir', label: 'Jammu and Kashmir' },
    { value: 'Jharkhand', label: 'Jharkhand' },
    { value: 'Karnataka', label: 'Karnataka' },
    { value: 'Kerala', label: 'Kerala' },
    { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
    { value: 'Maharashtra', label: 'Maharashtra' },
    { value: 'Manipur', label: 'Manipur' },
    { value: 'Meghalaya', label: 'Meghalaya' },
    { value: 'Mizoram', label: 'Mizoram' },
    { value: 'Nagaland', label: 'Nagaland' },
    { value: 'Odisha', label: 'Odisha' },
    { value: 'Punjab', label: 'Punjab' },
    { value: 'Rajasthan', label: 'Rajasthan' },
    { value: 'Sikkim', label: 'Sikkim' },
    { value: 'Tamil Nadu', label: 'Tamil Nadu' },
    { value: 'Telangana', label: 'Telangana' },
    { value: 'Tripura', label: 'Tripura' },
    { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
    { value: 'Uttarakhand', label: 'Uttarakhand' },
    { value: 'West Bengal', label: 'West Bengal' },
]

// Validation schema
const validationSchema = yup.object({
    name: yup.string().required('Full Name is required'),
    email: yup.string().email('Invalid email address').required('Email is required'),
    mobile: yup.string().matches(/^[0-9]{10}$/, 'Mobile number must be 10 digits').required('Mobile number is required'),
    address: yup.string().required('Address is required'),
    state: yup.string().required('State is required'),
    city: yup.string().required('City is required'),
    pincode: yup.string().matches(/^[0-9]{6}$/, 'Pincode must be 6 digits').required('Pincode is required'),
    username: yup.string().required('Username is required'),
    password: yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
})

export default function AssociateRegistration({ isEdit = false }) {
    const navigate = useNavigate()
    const { id } = useParams()
    const { user } = useAuth()
    const location = useLocation()
    const associateToEdit = location.state?.associateToEdit
    const [selectedDealerState, setSelectedDealerState] = useState('')
    const [selectedAssociateState, setSelectedAssociateState] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [dealers, setDealers] = useState([])
    const [filteredDealers, setFilteredDealers] = useState([])
    const [errorMessage, setErrorMessage] = useState('')
    const [selectedDealer, setSelectedDealer] = useState(null)
    const [selectedImage, setSelectedImage] = useState(null)

    console.log(associateToEdit)

    useEffect(() => {
        if (isEdit && associateToEdit) {
            // Find the matching state option for the associate
            const stateOption = indianStates.find(state => state.value === associateToEdit.state);
            setSelectedAssociateState(stateOption || null);

            // // Fetch dealers if user is admin
            // if (user.role === 'admin') {
            //     fetchDealers();
            // }
            if (associateToEdit.parent_id === 1) {
                setSelectedDealer({ value: 'admin', label: 'Admin' });
                setSelectedDealerState('All States');
            } else {
                // Find the matching state option for the associate
                const stateOption = indianStates.find(state => state.value === associateToEdit.state);
                setSelectedAssociateState(stateOption || null);

                // Fetch dealers if user is admin
                if (user.role === 'admin') {
                    fetchDealers();
                }
            }

            formik.setValues({
                name: associateToEdit.name || '',
                email: associateToEdit.email || '',
                mobile: associateToEdit.mobile || '',
                address: associateToEdit.address || '',
                state: associateToEdit.state || '',
                city: associateToEdit.city || '',
                pincode: associateToEdit.pincode || '',
                username: associateToEdit.username || '',
                parent_id: associateToEdit.parent_id || id || (user.role === 'dealer' ? user.id : ''),
            });
        }
    }, [associateToEdit, isEdit, user.role]);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            mobile: '',
            address: '',
            state: '',
            city: '',
            pincode: '',
            username: '',
            password: '',
            parent_id: id || (user.role === 'dealer' ? user.id : ''),
            image: '',
        },
        validationSchema: isEdit 
            ? validationSchema.omit(['password']).shape({
                image: yup.string(),
              })
            : validationSchema.shape({
                image: yup.string(),
              }),
        onSubmit: async (values) => {
            setIsLoading(true)
            setErrorMessage('')
            try {
                const userData = {
                    ...values,
                    role: 'associate',
                    image: selectedImage,
                }
                if (isEdit) {
                    const updateData = {
                        parent_id: values.parent_id,
                        user_id: associateToEdit.id,
                        update_data: {
                            name: values.name,
                            email: values.email,
                            address: values.address,
                            city: values.city,
                            state: values.state,
                            pincode: values.pincode,
                            mobile: values.mobile,
                            image: selectedImage,
                        }
                    }
                    const response = await axiosApi.post('/updateUserByParentId', updateData)
                    console.log('Associate update successful:', response.data)
                } else {
                    const response = await axiosApi.post('/register', userData)
                    console.log('Associate registration successful:', response.data)
                }
                setShowSuccessModal(true)
                setTimeout(() => {
                    setShowSuccessModal(false)
                    navigate(-1)
                }, 2000)
            } catch (error) {
                console.error('Error processing associate:', error)
                if (error.response && error.response.status === 409) {
                    setErrorMessage('User with this Email, Username, or Mobile already exists')
                } else {
                    setErrorMessage('An error occurred. Please try again.')
                }
            } finally {
                setIsLoading(false)
            }
        },
    })

    const fetchDealers = async () => {
        try {
            const response = await axiosApi.get('/getAllUserByParent');
            const dealersData = response.data.data.filter(user => user.role === 'dealer');
            setDealers(dealersData);
            setFilteredDealers(dealersData);

            if (isEdit && associateToEdit) {
                const dealerOption = dealersData.find(dealer => dealer.id === associateToEdit.parent_id);
                if (dealerOption) {
                    setSelectedDealer({ value: dealerOption.id, label: dealerOption.name });
                    setSelectedDealerState(dealerOption.state || '');
                    // Update filtered dealers based on the selected state
                    const filtered = dealersData.filter(dealer => dealer.state.toLowerCase() === dealerOption.state.toLowerCase());
                    setFilteredDealers(filtered);
                }
            }
        } catch (error) {
            console.error('Error fetching dealers:', error);
        }
    };

    useEffect(() => {
        if (user.role === 'admin') {
            fetchDealers();
        }
    }, [user.role]);

    const handleDealerStateChange = (selectedOption) => {
        const selectedState = selectedOption.value;
        setSelectedDealerState(selectedState);

        if (selectedState === '') {
            setFilteredDealers(dealers);
        } else {
            const filtered = dealers.filter(dealer => dealer.state.toLowerCase() === selectedState.toLowerCase());
            setFilteredDealers(filtered);
        }
    };

    const handleAssociateStateChange = (selectedOption) => {
        setSelectedAssociateState(selectedOption);
        formik.setFieldValue('state', selectedOption.value);
    };

    const handleDealerChange = (selectedOption) => {
        if (selectedOption.value === 'admin') {
            formik.setFieldValue('parent_id', user.id);
        } else {
            formik.setFieldValue('parent_id', selectedOption.value);
        }
        setSelectedDealer(selectedOption);
    };

    const selectStyles = {
        control: (base) => ({
            ...base,
            minHeight: '42px',
            paddingLeft: '2.5rem',
            borderRadius: '0.5rem',
            borderColor: '#e5e7eb',
            backgroundColor: 'white',
            '&:hover': { borderColor: '#84cc16' },
        }),
        input: (base) => ({
            ...base,
            margin: 0,
            padding: 0,
        }),
        menu: (base) => ({
            ...base,
            backgroundColor: 'white',
            zIndex: 1000,
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused ? '#f3f4f6' : 'white',
            '&:active': {
                backgroundColor: '#e5e7eb',
            },
            '&[aria-selected="true"]': {
                backgroundColor: '#34d399', // Keep this green color for selected options
            },
        }),
    }

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
          try {
            const options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
              useWebWorker: true
            };
            
            const compressedFile = await imageCompression(file, options);
            const reader = new FileReader();
            reader.onloadend = () => {
              setSelectedImage(reader.result);
              formik.setFieldValue('image', reader.result);
            };
            reader.readAsDataURL(compressedFile);
          } catch (error) {
            console.error('Error compressing image:', error);
            setErrorMessage('Error processing image. Please try again.');
          }
        }
    };

    const inputFields = [
        { name: 'name', icon: User, placeholder: 'Full Name' },
        { name: 'email', icon: Mail, placeholder: 'Email Address' },
        { name: 'mobile', icon: Phone, placeholder: 'Mobile Number' },
        { name: 'address', icon: MapPin, placeholder: 'Address' },
        { name: 'state', icon: Flag, placeholder: 'State', type: 'select' },
        { name: 'city', icon: Home, placeholder: 'City' },
        { name: 'pincode', icon: MapPin, placeholder: 'Pincode', type: 'number' },
        { name: 'username', icon: User, placeholder: 'Username' },
        { name: 'image', icon: ImageIcon, placeholder: 'Upload Image', type: 'file' },
    ]

    return (
        <motion.div
            className="max-w-md mx-auto bg-white p-6 rounded-xl shadow-2xl"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <h2 className="text-2xl font-bold mb-6 text-center text-lime-600">
                {isEdit ? 'Edit Associate' : 'Associate Registration'}
            </h2>
            <form onSubmit={formik.handleSubmit} className="space-y-6">
                <div className="space-y-4">
                    {user.role === 'admin' && (
                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold text-gray-700">Assign to Dealer</h3>
                            <div className="relative">
                                <Flag className="absolute top-3 left-3 text-gray-400 z-10" size={20} />
                                <Select
                                    options={[
                                        { value: '', label: 'All States' },
                                        ...indianStates,
                                    ]}
                                    onChange={handleDealerStateChange}
                                    placeholder="Select Dealer's State"
                                    className="w-full"
                                    styles={selectStyles}
                                    value={
                                        selectedDealerState === 'All States'
                                            ? { value: '', label: 'All States' }
                                            : indianStates.find(state => state.value === selectedDealerState) || null
                                    }
                                />
                            </div>
                            <div className="relative">
                                <User className="absolute top-3 left-3 text-gray-400 z-10" size={20} />
                                <Select
                                    options={[
                                        { value: 'admin', label: 'Admin' },
                                        ...filteredDealers.map(dealer => ({ value: dealer.id, label: dealer.name })),
                                    ]}
                                    onChange={handleDealerChange}
                                    placeholder="Select Dealer"
                                    className="w-full"
                                    styles={selectStyles}
                                    value={selectedDealer}
                                />
                            </div>
                        </div>
                    )}

                    <h3 className="text-lg font-semibold text-gray-700">Personal Information</h3>
                    {inputFields.map((field) => (
                        <div key={field.name} className="relative">
                            <field.icon className="absolute top-3 left-3 text-gray-400 z-10" size={20} />
                            {field.name === 'state' ? (
                                <Select
                                    options={indianStates}
                                    onChange={handleAssociateStateChange}
                                    placeholder="Select State"
                                    className="w-full"
                                    styles={selectStyles}
                                    onBlur={() => formik.setFieldTouched('state', true)}
                                    value={selectedAssociateState}
                                />
                            ) : field.name === 'image' ? (
                                <div className="flex items-center">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageUpload}
                                        className="hidden"
                                        id="imageUpload"
                                    />
                                    <label
                                        htmlFor="imageUpload"
                                        className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500 cursor-pointer"
                                    >
                                        {selectedImage ? 'Image Selected' : 'Upload Image'}
                                    </label>
                                </div>
                            ) : (
                                <input
                                    type={field.type || 'text'}
                                    name={field.name}
                                    placeholder={field.placeholder}
                                    required
                                    className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                                    value={formik.values[field.name]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    maxLength={field.name === 'pincode' ? 6 : undefined}
                                />
                            )}
                            {formik.touched[field.name] && formik.errors[field.name] ? (
                                <div className="text-red-500 text-sm mt-1">{formik.errors[field.name]}</div>
                            ) : null}
                        </div>
                    ))}
                    {selectedImage && (
                        <div className="mt-4">
                            <img src={selectedImage} alt="Selected" className="w-32 h-32 object-cover rounded-lg" />
                        </div>
                    )}
                    {!isEdit && (
                        <div className="relative">
                            <Key className="absolute top-3 left-3 text-gray-400 z-10" size={20} />
                            <input
                                type="password"
                                name="password"
                                placeholder="Password"
                                className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.password && formik.errors.password ? (
                                <div className="text-red-500 text-sm mt-1">{formik.errors.password}</div>
                            ) : null}
                        </div>
                    )}
                    {errorMessage && (
                        <motion.div
                            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
                            role="alert"
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                        >
                            <AlertCircle className="inline-block mr-2" size={20} />
                            <span className="block sm:inline">{errorMessage}</span>
                        </motion.div>
                    )}
                </div>

                <motion.button
                    type="submit"
                    className="w-full bg-lime-600 text-white py-2 px-4 rounded-lg hover:bg-lime-700 transition duration-300 flex items-center justify-center"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <Loader className="animate-spin mr-2" size={20} />
                    ) : null}
                    {isLoading ? 'Processing...' : (isEdit ? 'Update Associate' : 'Register Associate')}
                </motion.button>
            </form>

            <AnimatePresence>
                {showSuccessModal && (
                    <motion.div
                        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <motion.div
                            className="bg-white p-6 rounded-lg shadow-xl text-center z-50"
                            initial={{ scale: 0.8, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.8, opacity: 0 }}
                        >
                            <CheckCircle className="text-lime-600 w-16 h-16 mx-auto mb-4" />
                            <h3 className="text-2xl font-bold text-gray-800 mb-2">Registration Successful!</h3>
                            <p className="text-gray-600">The associate has been registered successfully.</p>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    )
}