import React from 'react';
import { motion } from 'framer-motion';
import { FileX, RefreshCw } from 'lucide-react';

const NoReportsFound = ({ onRefresh }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col items-center justify-center p-8 bg-white rounded-lg shadow-md"
    >
      <FileX size={64} className="text-lime-500 mb-4" />
      <h3 className="text-2xl font-semibold text-gray-800 mb-2">No Reports Found</h3>
      <p className="text-gray-600 text-center mb-6">
        It looks like there are no reports available at the moment.
      </p>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={onRefresh}
        className="flex items-center px-4 py-2 bg-lime-500 text-white rounded-md hover:bg-lime-600 transition-colors duration-300"
      >
        <RefreshCw size={20} className="mr-2" />
        Refresh
      </motion.button>
    </motion.div>
  );
};

export default NoReportsFound;