import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Search, Calendar, Eye, Trash2, Loader, MapPin } from 'lucide-react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import ConfirmationDialog from './ConfirmationDialog'
import axiosApi from '../api/axiosApi'

// List of Indian states (same as in DealerRegistration.jsx)
const indianStates = [
    { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
    { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
    { value: 'Assam', label: 'Assam' },
    { value: 'Bihar', label: 'Bihar' },
    { value: 'Chhattisgarh', label: 'Chhattisgarh' },
    { value: 'Delhi', label: 'Delhi' },
    { value: 'Goa', label: 'Goa' },
    { value: 'Gujarat', label: 'Gujarat' },
    { value: 'Haryana', label: 'Haryana' },
    { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
    { value: 'Jharkhand', label: 'Jharkhand' },
    { value: 'Karnataka', label: 'Karnataka' },
    { value: 'Kerala', label: 'Kerala' },
    { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
    { value: 'Maharashtra', label: 'Maharashtra' },
    { value: 'Manipur', label: 'Manipur' },
    { value: 'Meghalaya', label: 'Meghalaya' },
    { value: 'Mizoram', label: 'Mizoram' },
    { value: 'Nagaland', label: 'Nagaland' },
    { value: 'Odisha', label: 'Odisha' },
    { value: 'Punjab', label: 'Punjab' },
    { value: 'Rajasthan', label: 'Rajasthan' },
    { value: 'Sikkim', label: 'Sikkim' },
    { value: 'Tamil Nadu', label: 'Tamil Nadu' },
    { value: 'Telangana', label: 'Telangana' },
    { value: 'Tripura', label: 'Tripura' },
    { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
    { value: 'Uttarakhand', label: 'Uttarakhand' },
    { value: 'West Bengal', label: 'West Bengal' },
]

export default function DealerList() {
    const [dealers, setDealers] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [searchTerm, setSearchTerm] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [stateFilter, setStateFilter] = useState(null)
    const [deleteConfirmation, setDeleteConfirmation] = useState({ isOpen: false, dealerId: null, parent_id: null })
    const [isDeleting, setIsDeleting] = useState(false)

    useEffect(() => {
        fetchDealers()
    }, [])

    const fetchDealers = async () => {
        setIsLoading(true)
        try {
            const response = await axiosApi.get('/getAllUserByParent')
            const dealersData = response.data.data.filter(user => user.role === 'dealer')
            const dealersWithAssociates = await Promise.all(dealersData.map(async (dealer) => {
                const associatesResponse = await axiosApi.get(`/getAssociateByDealer?dealerId=${dealer.id}`)
                return {
                    ...dealer,
                    associateCount: associatesResponse.data.data.length
                }
            }))
            setDealers(dealersWithAssociates)
        } catch (error) {
            console.error('Error fetching dealers:', error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleRemoveDealer = (id, parent_id) => {
        setDeleteConfirmation({ isOpen: true, dealerId: id, parent_id: parent_id })
    }

    const confirmDelete = async () => {
        if (deleteConfirmation.dealerId) {
            setIsDeleting(true)
            try {
                await axiosApi.post(`/deleteUser?userToDelete=${deleteConfirmation.dealerId}&parent_id=${deleteConfirmation.parent_id}`)
                setDealers(dealers.filter((dealer) => dealer.id !== deleteConfirmation.dealerId))
            } catch (error) {
                console.error('Error deleting dealer:', error)
            } finally {
                setIsDeleting(false)
                setDeleteConfirmation({ isOpen: false, dealerId: null })
            }
        }
    }

    const filteredDealers = dealers.filter(dealer =>
        dealer.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (!startDate || new Date(dealer.createdAt) >= new Date(startDate)) &&
        (!endDate || new Date(dealer.createdAt) <= new Date(endDate)) &&
        (!stateFilter || dealer.state.toLowerCase() === stateFilter.value.toLowerCase())
    )

    return (
        <motion.div
            className="bg-white shadow-lg rounded-lg overflow-hidden"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="px-6 py-4 bg-lime-600 text-white">
                <h3 className="text-xl font-semibold">Dealer List</h3>
            </div>
            <div className="p-6">
                <div className="mb-6 flex flex-wrap gap-4">
                    <div className="relative flex-1 min-w-[200px]">
                        <Search className="absolute top-3 left-3 text-gray-400" size={20} />
                        <input
                            type="text"
                            placeholder="Search dealers..."
                            className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-wrap gap-2">
                        <div className="relative">
                            <Calendar className="absolute top-3 left-3 text-gray-400" size={20} />
                            <input
                                type="date"
                                className="pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>
                        <div className="relative">
                            <Calendar className="absolute top-3 left-3 text-gray-400" size={20} />
                            <input
                                type="date"
                                className="pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>
                        <div className="relative min-w-[200px]">
                            <MapPin className="absolute top-3 left-3 text-gray-400 z-10" size={20} />
                            <Select
                                options={indianStates}
                                value={stateFilter}
                                onChange={setStateFilter}
                                placeholder="Filter by state..."
                                isClearable
                                className="w-full"
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        minHeight: '42px',
                                        paddingLeft: '2.5rem',
                                        borderRadius: '0.5rem',
                                        borderColor: '#e5e7eb',
                                        backgroundColor: 'white',
                                        '&:hover': { borderColor: '#84cc16' },
                                    }),
                                    input: (base) => ({
                                        ...base,
                                        margin: 0,
                                        padding: 0,
                                    }),
                                    menu: (base) => ({
                                        ...base,
                                        backgroundColor: 'white',
                                        zIndex: 1000,
                                    }),
                                    option: (base, state) => ({
                                        ...base,
                                        backgroundColor: state.isFocused ? '#f3f4f6' : 'white',
                                        '&:active': {
                                            backgroundColor: '#e5e7eb',
                                        },
                                        '&[aria-selected="true"]': {
                                            backgroundColor: '#34d399',
                                        },
                                    }),
                                }}
                            />
                        </div>
                    </div>
                </div>

                <AnimatePresence>
                    {isLoading ? (
                        <motion.div
                            className="flex justify-center items-center h-64"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <Loader className="animate-spin text-lime-600" size={48} />
                        </motion.div>
                    ) : filteredDealers.length > 0 ? (
                        filteredDealers.map((dealer) => (
                            <motion.div
                                key={dealer.id}
                                className="bg-gray-50 rounded-lg p-4 mb-4 flex justify-between items-center"
                                initial={{ opacity: 0, y: -20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -20 }}
                                transition={{ duration: 0.3 }}
                            >
                                <div>
                                    <h4 className="text-lg font-semibold text-gray-800">{dealer.name}</h4>
                                    <p className="text-sm text-gray-600">Email: {dealer.email}</p>
                                    <p className="text-sm text-gray-600">Mobile: {dealer.mobile}</p>
                                    <p className="text-sm text-gray-600">Location: {dealer.city}, {dealer.state}</p>
                                    <p className="text-sm text-gray-600">Joined: {new Date(dealer.createdAt).toLocaleDateString()}</p>
                                    <p className="text-sm text-gray-600">Associates: {dealer.associateCount}</p>
                                </div>
                                <div className="flex space-x-2 items-center">
                                    <Link
                                        to={`/dealers/${dealer.id}`}
                                        className="text-lime-600 hover:text-lime-800"
                                    >
                                        <motion.button
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className="p-1"
                                        >
                                            <Eye size={20} />
                                        </motion.button>
                                    </Link>
                                    <motion.button
                                        onClick={() => handleRemoveDealer(dealer.id, dealer.parent_id)}
                                        className="text-red-600 hover:text-red-800 p-1"
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        disabled={isDeleting}
                                    >
                                        <Trash2 size={20} />
                                    </motion.button>
                                </div>
                            </motion.div>
                        ))
                    ) : (
                        <motion.div
                            className="bg-gray-50 rounded-lg p-8 text-center"
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.3 }}
                        >
                            <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <h3 className="mt-2 text-sm font-medium text-gray-900">No dealers found</h3>
                            <p className="mt-1 text-sm text-gray-500">
                                Try adjusting your search or filter criteria.
                            </p>
                            <div className="mt-6">
                                <button
                                    type="button"
                                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
                                    onClick={() => {
                                        setSearchTerm('');
                                        setStartDate('');
                                        setEndDate('');
                                        setStateFilter(null);
                                    }}
                                >
                                    Clear filters
                                </button>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
            <ConfirmationDialog
                isOpen={deleteConfirmation.isOpen}
                onClose={() => setDeleteConfirmation({ isOpen: false, dealerId: null })}
                onConfirm={() => confirmDelete()}
                title="Delete Dealer"
                message="Are you sure you want to delete this dealer? This action cannot be undone."
                isLoading={isDeleting}
                confirmText={isDeleting ? 'Deleting...' : 'Delete'}
                confirmIcon={isDeleting ? Loader : Trash2}
            />
        </motion.div>
    )
}